import axios from 'axios';
import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';

import ErrorStyle from '../components/ErrorStyle';
import WordsSection from '../components/pages-section/words/WordsSection';
import { useAuthContext } from '../contexts';

export default function Words() {
  const { token } = useAuthContext();

  const [words, setWords] = useState([]);
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}words/admin/word/`, {
          headers: {
            Authorization: token,
          },
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
          },
        });
        if (!totalRows) {
          setTotalRows(res.data.count);
        }
        if (words) {
          const newWords = res.data.results.filter((el) => !words.some((w) => w.id === el.id));
          setWords((prev) => [...prev, ...newWords]);
        } else {
          setWords(res.data.results);
        }
      } catch (err) {
        const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
        setErr(detail);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // useEffect(() => {
  //   let mounted = true;

  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}words/admin/word/`, {
  //       headers: {
  //         Authorization: token,
  //       },
  //       params: {
  //         limit: rowsPerPage,
  //         offset: page * rowsPerPage,
  //       },
  //     })
  //     .then((res) => {
  //       if (mounted) {
  //         setWords(res.data.results);
  //         if (!totalRows) {
  //           setTotalRows(res.data.count);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       if (axios.isCancel(err)) {
  //         const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
  //         setErr(detail);
  //       } else {
  //         throw err;
  //       }
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });

  //   return () => {
  //     mounted = false;
  //   };
  // }, [page, rowsPerPage]);

  return (
    <Page title="Words">
      <Container>
        <WordsSection
          loading={loading}
          users={words}
          setUsers={setWords}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={totalRows}
        />
        {!words && err && <ErrorStyle err={err} />}
      </Container>
    </Page>
  );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';
import ErrorStyle from '../components/ErrorStyle';
import { useAuthContext } from '../contexts';
import NewWordsSection from '../components/pages-section/new-words/NewWordsSection';

export default function NewWords() {
  const { token } = useAuthContext();

  const [newWords, setNewWords] = useState([]);
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}words/admin/new-words/`, {
        headers: {
          Authorization: token,
        },
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        },
      });
      if (!totalRows) {
        setTotalRows(res.data.count);
      }
      if (newWords) {
        res.data.results.forEach((el) => {
          const w = newWords?.find((w) => w.id === el.id);
          if (!w) {
            setNewWords((prev) => [...prev, el]);
          }
        });
        return;
      }
      setNewWords(res.data.results);
    } catch (err) {
      const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
      setErr(detail);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  return (
    <Page title="Words">
      <Container>
        <NewWordsSection
          newWords={newWords}
          setNewWords={setNewWords}
          loading={loading}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={totalRows}
        />
        {!newWords && err && <ErrorStyle err={err} />}
      </Container>
    </Page>
  );
}

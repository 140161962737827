import { useParams } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import './custom.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// @mui
import { Container, Stack, Typography, Card } from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useAuthContext } from '../../../contexts/authContext';
// routes
// components
import Page from '../../Page';
import HeaderBreadcrumbs from '../../Breadcrumbs';
import { Error, Success } from '../../Alert';

import { FormProvider, RHFSelect, RHFTextField } from '../../hook-form';
import Backdrop from '../../ui/backdrop';

export default function DictionaryUpdateSection() {
  const { token } = useAuthContext();

  const [descriptionData, setDescriptionData] = useState('');
  const { id } = useParams();
  const [dictionary, setDictionary] = useState([]);

  const [err, setErr] = useState(null);
  const [alert, setAlert] = useState(null);
  const [dictionaryType, setDictionaryType] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(null);
      setErr(null);
    }, 3000);

    return () => clearInterval(interval);
  }, [err, alert]);

  const Schema = Yup.object().shape({
    title: Yup.string('title must be a string').required(`So'z kiritilishi shart`),
    type_title: Yup.string('type title must be a string'),
  });


  const defaultValues = useMemo(
    () => ({
      title: dictionary?.title || '',
      type_title: dictionary?.type_title || '',
      dictionary_type: dictionary?.dictionary_type || 0,
      description: dictionary?.description || '',
    }),
    [dictionary]
  );

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}dictionary/dictionary-type/`, {
          headers: {
            Authorization: token,
          },
        });
        setDictionaryType(res.data.results);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [token]);

  useEffect(() => {
    reset(defaultValues);
  }, [dictionary]);

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  useEffect(() => {
    getDictionary(id);
  }, [id]);

  const getDictionary = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}dictionary/${id}/`, {
        headers: {
          Authorization: token,
        },
      });
      setDictionary(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}dictionary/${id}/`,
        {
          title: data.title,
          description: descriptionData,
          dictionary_type: data.dictionary_type,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAlert('Dictionary is updated successfully!');
    } catch (err) {
      const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
      setErr(detail);
    }
  };

  return (
    <Page title="Create a word">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Update a dictionary
          </Typography>
        </Stack>
        <HeaderBreadcrumbs
          heading={'Create a dictionary'}
          links={[
            { name: 'Dashboard', href: `/dashboard/app` },
            {
              name: 'dictionary',
              href: `/dashboard/dictionary/list`,
            },
            { name: 'Update dictionary' },
          ]}
        />
        <Backdrop tepa={300} chap={20} />
        <Backdrop tepa={300} chap={1010} />
        <Card
          sx={{ padding: '15px', marginTop: '25px' }}
          style={{
            backdropFilter: 'blur(23px) saturate(180%)',
            WebkitBackdropFilter: 'blur(16px) saturate(180%)',
            backgroundColor: 'rgba(17, 25, 40, 0.55)',
            border: '1px solid rgba(255, 255, 255, 0.125)',
            zIndex: 2,
          }}
        >
          {err && <Error err={err} setErr={setErr} />}
          {alert && <Success alert={alert} setAlert={setAlert} />}
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} mb={3} direction={{ xs: 'column', sm: 'row' }}>
              <RHFTextField name="title" label={'title'} />
              <RHFSelect name="dictionary_type" label="Izohli lug'at turi" placeholder="izohli lug'at turi">
                <option key={0} value={Number.parseInt(defaultValues?.dictionary_type, 10)}>
                  {defaultValues?.type_title}
                </option>
                {dictionaryType.map((option, index) => (
                  (Number.parseInt(option.id, 10) === defaultValues?.dictionary_type) ? 
                  '': 
                  <option key={index} value={Number.parseInt(option.id, 10)}>
                    {option.title} {option.id === defaultValues?.dictionary_type}
                  </option>
                ))}
              </RHFSelect>
            </Stack>
            <Stack spacing={3} mb={3}>
              <CKEditor
                editor={ClassicEditor}
                data={dictionary.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescriptionData(data);
                }}
              />
            </Stack>
            <Stack>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Update
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}

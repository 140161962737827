import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Words from './pages/Words';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import { useAuthContext } from './contexts';
import CreateWordSection from './components/pages-section/words/CreateWordSection';
import UpdateWordSection from './components/pages-section/words/UpdateWordSection';
import NewWords from './pages/NewWords';
import UpdateNewWordSection from './components/pages-section/new-words/UpdateNewWordSection';
import Dictionary from './pages/Dictionary';
import DictionaryCreateSection from './components/pages-section/dictionary/DictionaryCreateSection';
import DictionaryUpdateSection from './components/pages-section/dictionary/DictionaryUpdateSection';
import Admins from './pages/Admins';
import AdminUpdateSection from './components/pages-section/admins/AdminUpdateSection';
import AdminCreateSection from './components/pages-section/admins/AdminCreateSection';
import Contacts from './pages/Contacts';
import ContactsViewSection from './components/pages-section/contacts/ContactsViewSection';
import DictionaryTypePage from './pages/DictionaryType';
import CraeteDictionaryType from './components/pages-section/dictionary-type/CreateDictionaryType';
import UploadDictionary from './components/pages-section/upload-dictionary/upload-dictionary';
import UpdateDictionaryType from './components/pages-section/dictionary-type/UpdateDictionaryType';

// ----------------------------------------------------------------------

export default function Router() {
  const { token } = useAuthContext();

  return useRoutes(
    !token
      ? [
          {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
              { path: '/', element: <Navigate to="/login" /> },
              { path: '404', element: <NotFound /> },
              { path: '*', element: <Navigate to="/404" /> },
            ],
          },
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: '*',
            element: <Navigate to="/404" replace />,
          },
        ]
      : [
          {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
              { path: 'app', element: <DashboardApp /> },
              { path: 'words', element: <Navigate to="/dashboard/words/list" /> },
              { path: 'words/list', element: <Words /> },
              { path: 'words/create', element: <CreateWordSection /> },
              {
                path: 'words/update',
                element: <UpdateWordSection />,
                children: [{ path: ':id', element: <UpdateWordSection /> }],
              },
              { path: 'new-words', element: <Navigate to="/dashboard/new-words/list" /> },
              { path: 'new-words/list', element: <NewWords /> },
              { path: 'new-words/create', element: <CreateWordSection /> },
              {
                path: 'new-words/update',
                element: <UpdateNewWordSection />,
                children: [{ path: ':id', element: <UpdateWordSection /> }],
              },
              { path: 'dictionary', element: <Navigate to="/dashboard/dictionary/list" /> },
              { path: 'dictionary/list', element: <Dictionary /> },
              { path: 'dictionary/create', element: <DictionaryCreateSection /> },
              {
                path: 'dictionary/update',
                element: <DictionaryUpdateSection />,
                children: [{ path: ':id', element: <DictionaryUpdateSection /> }],
              },
              { path: 'admins', element: <Navigate to="/dashboard/admins/list" /> },
              { path: 'admins/list', element: <Admins /> },
              { path: 'admins/create', element: <AdminCreateSection /> },
              {
                path: 'admins/update',
                element: <AdminUpdateSection />,
                children: [{ path: ':id', element: <AdminUpdateSection /> }],
              },
              { path: 'contacts', element: <Navigate to="/dashboard/contacts/list" /> },
              { path: 'contacts/list', element: <Contacts /> },
              {
                path: 'contacts/view',
                element: <ContactsViewSection />,
                children: [{ path: ':id', element: <ContactsViewSection /> }],
              },
              { path: 'dictionary-type', element: <Navigate to="/dashboard/dictionary-type/list" /> },
              { path: 'dictionary-type/list', element: <DictionaryTypePage /> },
              {
                path: 'dictionary-type/update',
                element: <UpdateDictionaryType />,
                children: [{ path: ':id', element: <UpdateDictionaryType /> }],
              },
              { path: 'dictionary-type/create', element: <CraeteDictionaryType /> },
              { path: 'dictionary-upload/upload', element: <UploadDictionary /> },
            ],
          },
          {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
              { path: '/', element: <Navigate to="/dashboard/app" /> },
              { path: '404', element: <NotFound /> },
              { path: '*', element: <Navigate to="/404" /> },
            ],
          },
          {
            path: '*',
            element: <Navigate to="/404" replace />,
          },
        ]
  );
}

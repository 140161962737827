import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import upsetImage from '../assets/upset.png';

ErrorStyle.propTypes = {
  err: PropTypes.string,
};

function ErrorStyle({ err }) {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px 20px',
        backgroundColor: '#d32f2f',
        color: '#fff',
        fontWeight: 600,
        fontSize: '18px',
        borderRadius: '10px',
      }}
    >
      <img src={upsetImage} alt="error" loading="lazy" width="32" height="32" style={{ marginRight: '5px' }} />
      {err}
    </Box>
  );
}

export default ErrorStyle;

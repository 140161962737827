import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="../assets/logo-icon.png" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="45" height="45" viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10C0 4.47715 4.47715 0 10 0H135C140.523 0 145 4.47715 145 10V75C145 113.66 113.66 145 75 145H70C31.3401 145 0 113.66 0 75V10Z" fill="#2A6EF9"/>
        <rect x="58" width="28" height="39" fill="white"/>
        <path d="M88.2778 62.6471H88.2383H88.2778ZM72.5 62.6471H72.4606H72.5ZM56.7222 62.6471H56.6828H56.7222ZM84.3333 86.2941H100.111C104.468 86.2941 108 82.7652 108 78.4118V46.8824C108 42.529 104.468 39 100.111 39H44.8889C40.5319 39 37 42.529 37 46.8824V78.4118C37 82.7652 40.5319 86.2941 44.8889 86.2941H64.6111L84.3333 106V86.2941Z" fill="white"/>
        <path d="M88.2778 62.6471H88.2383M72.5 62.6471H72.4606M56.7222 62.6471H56.6828M84.3333 86.2941H100.111C104.468 86.2941 108 82.7652 108 78.4118V46.8824C108 42.529 104.468 39 100.111 39H44.8889C40.5319 39 37 42.529 37 46.8824V78.4118C37 82.7652 40.5319 86.2941 44.8889 86.2941H64.6111L84.3333 106V86.2941Z" stroke="white" strokeWidth="3.78822" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="72" cy="62" r="6" fill="#F9622A"/>
        <circle cx="52" cy="62" r="6" fill="#F9622A"/>
        <circle cx="92" cy="62" r="6" fill="#F9622A"/>
      </svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

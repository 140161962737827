import { useState, useEffect, useMemo, useDeferredValue } from 'react';
import './custom.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// @mui
import { Container, Stack, Typography, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useAuthContext } from '../../../contexts/authContext';

// routes
// components
import Page from '../../Page';
import HeaderBreadcrumbs from '../../Breadcrumbs';
import { Error, Success } from '../../Alert';
import { FormProvider, RHFSelect, RHFTextField } from '../../hook-form';
import Backdrop from '../../ui/backdrop';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export default function DictionaryCreateSection() {
  const [ckEditorPlaceholder, setCkEditorPlaceholder] = useState('<p></p>');
  const [isFound, setIsFound] = useState(false);
  const { token } = useAuthContext();
  const [err, setErr] = useState(null);
  const [alert, setAlert] = useState(null);
  const [dictionary, setDictionary] = useState([]);
  const [title, setTitle] = useState('');
  const deferredQuery = useDeferredValue(title);
  const [dictionaryType, setDictionaryType] = useState([]);
  const [descriptionEmpty, setDictionaryEmpty] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(null);
      setErr(null);
    }, 3000);

    return () => clearInterval(interval);
  }, [err, alert]);

  const Schema = Yup.object().shape({
    title: Yup.string(`So'z matn ko'rinishida bo'lishi kerak`).required(`So'z kiritilishi shart`),
    type_title: Yup.string(`Lug'at turi matn ko'rinishida bo'lishi kerak`).required(`Lug'at turi tanlanishi shart`),
  });

  const defaultValues = useMemo(
    () => ({
      title: dictionary[0]?.title || '',
      type_title: dictionary[0]?.type_title || '',
      description: dictionary[0]?.description || '',
    }),
    [dictionary]
  );

  useEffect(() => {
    reset(defaultValues);
  }, []);

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue,
  } = methods;

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}dictionary/dictionary-type/`, {
          headers: {
            Authorization: token,
          },
        });
        setDictionaryType(res.data.results);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [token]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        setIsFound(false);

        try {
          const res = await axios.get(`${process.env.REACT_APP_BASE_URL}dictionary/word/?q=${deferredQuery}`);

          if (res.data.length > 0) {
            setDictionary(res.data);
            return setIsFound(true);
          }

          setIsFound(false);
        } catch (err) {
          console.log(err);
        }
      })();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [title, deferredQuery]);

  const onSubmit = async (data) => {
    if (isFound) {
      try {
        await axios.put(
          `${process.env.REACT_APP_BASE_URL}dictionary/${dictionary[0].id}/`,
          {
            title: data.title,
            dictionary_type: data.type_title,
            description: data.description,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setAlert("lug'at muvaffaqiyatli yangilandi!");
      } catch (err) {
        const detail = err.response.data ? err.response.data.title : 'Something went wrong';
        setErr(detail);
      } finally {
        reset();
        setCkEditorPlaceholder('');
      }
    } else {
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}dictionary/`,
          {
            title: data.title,
            dictionary_type: data.type_title,
            description: data.description,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setAlert("lug'at muvaffaqiyatli qoshildi!");
      } catch (err) {
        const detail = err.response.data ? err.response.data.title : 'Something went wrong';
        setErr(detail);
      } finally {
        reset();
        setCkEditorPlaceholder('');
      }
    }

    setDictionary('');
  };

  return (
    <Page title="Lug'at yaratish">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Lug'at yaratish
          </Typography>
        </Stack>
        <HeaderBreadcrumbs
          heading={'Create a dictionary'}
          links={[
            { name: 'Dashboard', href: `/dashboard/app` },
            {
              name: "Lug'at",
              href: `/dashboard/dictionary`,
            },
            { name: "Lug'at yaratish" },
          ]}
        />
        <Backdrop tepa={300} chap={20} />
        <Backdrop tepa={300} chap={1010} />
        <Card
          sx={{ padding: '15px', marginTop: '25px' }}
          style={{
            backdropFilter: 'blur(23px) saturate(180%)',
            WebkitBackdropFilter: 'blur(16px) saturate(180%)',
            backgroundColor: 'rgba(17, 25, 40, 0.55)',
            border: '1px solid rgba(255, 255, 255, 0.125)',
            zIndex: 2,
          }}
        >
          {err && <Error err={err} setErr={setErr} />}
          {alert && <Success alert={alert} setAlert={setAlert} />}
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} mb={3} direction={{ xs: 'column', sm: 'row' }}>
              <RHFTextField
                name="title"
                label="Title"
                onChange={(event) => {
                  setTitle(event.target.value);
                  setValue('title', event.target.value);
                }}
                error={isFound}
                helperText={isFound && "Bu so'z mavjud!"}
              />

              <RHFSelect name="type_title" label="Izohli lug'at turi" placeholder="izohli lug'at turi">
                <option value="" />
                {dictionaryType.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.title}
                  </option>
                ))}
              </RHFSelect>
            </Stack>

            <Stack spacing={3} mb={3}>
              <LabelStyle>Description</LabelStyle>
              <CKEditor
                name="description"
                editor={ClassicEditor}
                config={{
                  toolbar: {
                    items: [
                      'heading',
                      '|',
                      'bold',
                      'underline',
                      'italic',
                      'strikethrough',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'undo',
                      'redo',
                    ],
                  },
                }}
                data={dictionary.length < 1 ? ckEditorPlaceholder : dictionary[0]?.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValue('description', data);
                }}
              />
              {descriptionEmpty ? (
                <Typography sx={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>
                  Description is required
                </Typography>
              ) : null}
            </Stack>
            <Stack>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                {isFound ? 'Yangilash' : 'Yaratish'}
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}

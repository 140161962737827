import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Tabs,
  Tab,
  Divider,
} from '@mui/material';
// components
import useTabs from '../../../hooks/useTabs';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { ContactsListHead, ContactsListToolbar, ContactsMoreMenu } from '../../../sections/@dashboard/contacts';
import { Error, Success } from '../../Alert';
import { useAuthContext } from '../../../contexts';
import Backdrop from '../../ui/backdrop';
import { fDate } from '../../../utils/formatTime';
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'name', alignRight: false },
  { id: 'email', label: 'email', alignRight: false },
  { id: 'description', label: 'description', alignRight: false },
  { id: 'unread', label: 'unread', alignRight: false },
  { id: 'time', label: 'time', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function applySortFilter(array, comparator, filterStatus, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
  }

  if (filterStatus === 'All') {
    return array;
  }
  if (filterStatus === 'Read') {
    return array.filter((item) => item.unread === false);
  }
  if (filterStatus === 'Unread') {
    return array.filter((item) => item.unread === true);
  }

  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

ContactsSection.propTypes = {
  contacts: PropTypes.any,
  setContacts: PropTypes.any,
};

export default function ContactsSection({ contacts, setContacts }) {
  const { token } = useAuthContext();
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('All');

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('time');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [err, setErr] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(null);
      setErr(null);
    }, 3000);

    return () => clearInterval(interval);
  }, [err, alert]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contacts.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contacts.length) : 0;

  const filteredDictionary = applySortFilter(contacts, getComparator(order, orderBy), filterStatus, filterName);

  const isUserNotFound = filteredDictionary.length === 0;

  return (
    <>
      <Backdrop tepa={400} chap={20} />
      <Backdrop tepa={300} chap={920} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Feedbacks list</Typography>
      </Stack>

      <Card
        style={{
          backdropFilter: 'blur(23px) saturate(180%)',
          WebkitBackdropFilter: 'blur(16px) saturate(180%)',
          backgroundColor: 'rgba(17, 25, 40, 0.55)',
          border: '1px solid rgba(255, 255, 255, 0.125)',
          zIndex: 2,
        }}
      >
        {err && <Error err={err} setErr={setErr} />}
        {alert && <Success alert={alert} setAlert={setAlert} />}
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterStatus}
          onChange={onChangeFilterStatus}
          sx={{ px: 2, pt: 1 }}
        >
          {['All', 'Read', 'Unread'].map((tab) => (
            <Tab disableRipple key={tab} label={tab} value={tab} />
          ))}
        </Tabs>
        <Divider />
        <ContactsListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ContactsListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={contacts.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredDictionary.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const isItemSelected = selected.indexOf(row.name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={row.id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, row.title)} />
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>

                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        {row.description.length > 100 ? `${row.description.slice(1, 90)}...` : row.description}
                      </TableCell>

                      <TableCell align="center">
                        <Iconify
                          icon={!row.unread ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
                          sx={{
                            width: 20,
                            height: 20,
                            color: 'success.main',
                            ...(row.unread && { color: 'warning.main' }),
                          }}
                        />
                      </TableCell>

                      <TableCell align="left">{fDate(row.created_at)}</TableCell>

                      <TableCell align="right">
                        <ContactsMoreMenu
                          navigator={`/dashboard/contacts/view/${row.id}`}
                          endpoint={`contact/admin/${row.id}/`}
                          id={row.id}
                          state={contacts}
                          setState={setContacts}
                          setAlert={setAlert}
                          setErr={setErr}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} sx={{ py: 2 }} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={contacts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

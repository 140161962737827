import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
// @mui
import { Container, Stack, Typography, Card } from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useAuthContext } from '../../../contexts/authContext';
// routes
// components
import Page from '../../Page';
import HeaderBreadcrumbs from '../../Breadcrumbs';

import { FormProvider, RHFTextField } from '../../hook-form';
import { Error, Success } from '../../Alert';
import Backdrop from '../../ui/backdrop';

export default function UpdateWordSection() {
  const { token } = useAuthContext();
  const [word, setWord] = useState('');
  const { id } = useParams();
  const [err, setErr] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(null);
      setErr(null);
    }, 3000);

    return () => clearInterval(interval);
  }, [err, alert]);

  const Schema = Yup.object().shape({
    title: Yup.string('category must be a string').required('category is required'),
  });

  const defaultValues = useMemo(
    () => ({
      title: word?.title || '',
    }),
    [word]
  );

  useEffect(() => {
    reset(defaultValues);
  }, [word]);

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  useEffect(() => {
    getWord(id);
  }, [id]);

  const getWord = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}words/admin/word/${id}`, {
        headers: {
          Authorization: token,
        },
      });
      setWord(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(word);

  const onSubmit = async (data) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}words/admin/word/${id}`,
        {
          title: data.title,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAlert('Word is updated successfully!');
    } catch (err) {
      const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
      setErr(detail);
    }
  };

  return (
    <Page title="Update a word">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Update a word
          </Typography>
        </Stack>
        <HeaderBreadcrumbs
          heading={'Create a word'}
          links={[
            { name: 'Dashboard', href: `/dashboard/app` },
            {
              name: 'words',
              href: `/dashboard/words`,
            },
            { name: 'create word' },
          ]}
        />
        <Backdrop tepa={400} chap={20} />
        <Backdrop tepa={300} chap={920} />
        <Card
          sx={{ padding: '15px', marginTop: '25px' }}
          style={{
            backdropFilter: 'blur(23px) saturate(180%)',
            WebkitBackdropFilter: 'blur(16px) saturate(180%)',
            backgroundColor: 'rgba(17, 25, 40, 0.55)',
            border: '1px solid rgba(255, 255, 255, 0.125)',
            zIndex: 2,
          }}
        >
          {err && <Error err={err} setErr={setErr} />}
          {alert && <Success alert={alert} setAlert={setAlert} />}
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} mb={3}>
              <RHFTextField name="title" label={'Title'} />
            </Stack>
            <Stack>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Update
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}

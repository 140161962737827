import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Card } from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useAuthContext } from '../../../contexts/authContext';
import { Error, Success } from '../../Alert';
// routes
// components
import Page from '../../Page';
import HeaderBreadcrumbs from '../../Breadcrumbs';

import { FormProvider, RHFTextField } from '../../hook-form';
import Backdrop from '../../ui/backdrop';

export default function CreateWordSection() {
  const [alert, setAlert] = useState(null);
  const [err, setErr] = useState(null);

  useState(() => {
    const interval = setInterval(() => {
      setAlert(null);
      setErr(null);
    }, 3000);

    return () => clearInterval(interval);
  }, [err, alert]);

  const { token } = useAuthContext();

  const Schema = Yup.object().shape({
    title: Yup.string('word must be a string').required('word is required'),
  });

  const defaultValues = {
    title: '',
  };

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}words/admin/word/`,
        {
          title: data.title,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAlert('words is created successfully!');
    } catch (error) {
      const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
      setErr(detail);
    }
  };

  return (
    <Page title="Create a word">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Create a new word
          </Typography>
        </Stack>
        {err && <Error err={err} setErr={setErr} />}
        {alert && <Success alert={alert} setAlert={setAlert} />}
        <HeaderBreadcrumbs
          heading={'Create a word'}
          links={[
            { name: 'Dashboard', href: `/dashboard/app` },
            {
              name: 'words',
              href: `/dashboard/words`,
            },
            { name: 'Create word' },
          ]}
        />
        <Backdrop tepa={300} chap={20} />
        <Backdrop tepa={250} chap={990} />
        <Card
          sx={{ padding: '15px', marginTop: '25px' }}
          style={{
            backdropFilter: 'blur(23px) saturate(180%)',
            WebkitBackdropFilter: 'blur(16px) saturate(180%)',
            backgroundColor: 'rgba(17, 25, 40, 0.55)',
            border: '1px solid rgba(255, 255, 255, 0.125)',
            zIndex: 2,
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} mb={3}>
              <RHFTextField name="title" label="word" />
            </Stack>
            <Stack>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Create
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}

import { Box } from '@mui/material';

function LoadingStyle() {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="preloader-spinner">
        <svg className="preloader-spinner-icon" viewBox="0 0 24 24">
          <path
            d="M 22.49772,12.000001 A 10.49772,10.497721 0 0 1 12,22.497722 10.49772,10.497721 0 0 1 1.5022797,12.000001 10.49772,10.497721 0 0 1 12,1.5022797 10.49772,10.497721 0 0 1 22.49772,12.000001 Z"
            fill="none"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </Box>
  );
}

export default LoadingStyle;

import axios from 'axios';
import PropTypes from 'prop-types';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useAuthContext} from './authContext';

const DictionaryContext = createContext();

DictionaryProvider.propTypes = {
    children: PropTypes.any,
};

export function DictionaryProvider({children}) {
    const {token} = useAuthContext();
    const [dictionary, setDictionary] = useState([]);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);

    async function getUsers(token) {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}dictionary/`, {
                headers: {
                    Authorization: token
                }
            });
            setDictionary(res.data);
        } catch (err) {
            const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
            setErr(detail);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getUsers(token);
    }, [token]);

    const value = useMemo(
        () => ({
            dictionary,
            setDictionary,
            loading,
            err,
        }),
        [dictionary, setDictionary, loading, err]
    );
    return <DictionaryContext.Provider value={value}>{children}</DictionaryContext.Provider>;
}

export const useDictionaryContext = () => {
    const context = useContext(DictionaryContext);
    if (context === 'undefined') {
        throw new Error('You have to use useDictionaryContext inside DictionaryProvider');
    }
    return context;
};

// @mui
import { useState, useEffect, useCallback } from 'react';
import { Container, Stack, Typography, Card } from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useAuthContext } from '../../../contexts/authContext';
// components
import Page from '../../Page';
import HeaderBreadcrumbs from '../../Breadcrumbs';

import { FormProvider, RHFUploadSingleFile } from '../../hook-form';
import Backdrop from '../../ui/backdrop';
import { Error, Success } from '../../Alert';

export default function UploadDictionary() {
  const { token } = useAuthContext();
  const [err, setErr] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(null);
      setErr(null);
    }, 3000);

    return () => clearInterval(interval);
  }, [err, alert]);

  const Schema = Yup.object().shape({
    file: Yup.mixed().required('File is required!'),
  });

  const defaultValues = {
    latincyril: 'LATIN',
    file: [],
  };

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
  } = methods;

  const values = watch();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('file', data.file[0]);
    formData.append('latincyril', 'latin');
    formData.append('import', 1);

    try {
      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}file/convert-file/`,
        data: formData,
        headers: {
          Authorization: token,
        },
      });
      setAlert(`${res.data} ta yangi so'z qoshildi!`);
    } catch (error) {
      const detail = err.response.data?.detail ? err.response.data.detail : 'Something went wrong!';
      setErr(detail);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setValue(
        'file',
        acceptedFiles.map((file) => file)
      );
    },
    [setValue]
  );

  const handleRemove = (file) => {
    const filteredItems = values.images?.filter((_file) => _file !== file);
    setValue('file', filteredItems);
  };
  const handleRemoveAll = () => {
    setValue('file', []);
  };

  return (
    <Page title="Upload dictionary">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            So'z yuklash
          </Typography>
        </Stack>
        <HeaderBreadcrumbs
          heading={'Upload dictionary'}
          links={[
            { name: 'Dashboard', href: `/dashboard/app` },
            {
              name: 'dictionary',
              href: `/dashboard/dictionary/`,
            },
            { name: "So'z yuklash" },
          ]}
        />
        <Backdrop tepa={300} chap={20} />
        <Backdrop tepa={250} chap={990} />
        {err && <Error err={err} setErr={setErr} />}
        {alert && <Success alert={alert} setAlert={setAlert} />}
        <Card
          sx={{ padding: '15px', marginTop: '25px' }}
          style={{
            backdropFilter: 'blur(23px) saturate(180%)',
            WebkitBackdropFilter: 'blur(16px) saturate(180%)',
            backgroundColor: 'rgba(17, 25, 40, 0.55)',
            border: '1px solid rgba(255, 255, 255, 0.125)',
            zIndex: 2,
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} mb={3}>
             
              <RHFUploadSingleFile
                name="file"
                accept="docx/*"
                maxSize={3145728}
                showPreview
                onDrop={handleDrop}
                onRemove={handleRemove}
                onRemoveAll={handleRemoveAll}
              />
            </Stack>
            <Stack>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Yuklash
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';
import ErrorStyle from '../components/ErrorStyle';
import { useAuthContext } from '../contexts';
import DictionarySection from '../components/pages-section/dictionary/DictionarySection';

export default function Dictionary() {
  const { token } = useAuthContext();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [dictionary, setDictionary] = useState([]);
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}dictionary/`, {
          headers: {
            Authorization: token,
          },
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
          },
        });
        if (!totalRows) {
          setTotalRows(res.data.count);
        }
        if (dictionary) {
          res.data.results.forEach((el) => {
            const w = dictionary?.find((w) => w.id === el.id);
            if (!w) {
              setDictionary((prev) => [...prev, el]);
            }
          });
          return;
        }
        setDictionary(res.data.results);
      } catch (err) {
        const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
        setErr(detail);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Page title="Words">
      <Container>
        <DictionarySection
          dictionary={dictionary}
          setDictionary={setDictionary}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          loading={loading}
          totalRows={totalRows}
        />
        {!dictionary && err && <ErrorStyle err={err} />}
      </Container>
    </Page>
  );
}

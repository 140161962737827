import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Typography, Card, IconButton, InputAdornment } from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useAuthContext } from '../../../contexts/authContext';
// routes
// components
import Page from '../../Page';
import HeaderBreadcrumbs from '../../Breadcrumbs';

import { FormProvider, RHFCheckbox, RHFTextField } from '../../hook-form';
import { Error, Success } from '../../Alert';
import Iconify from '../../Iconify';
import Backdrop from '../../ui/backdrop';

export default function AdminCreateSection() {
  const { token } = useAuthContext();
  const [admin, setAdmin] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [err, setErr] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(null);
      setErr(null);
    }, 3000);

    return () => clearInterval(interval);
  }, [err, alert]);

  const Schema = Yup.object().shape({
    firstname: Yup.string('firstname must be a string').required('firstname is required'),
    lastname: Yup.string('lastname must be a string').required('lastname is required'),
    email: Yup.string('email must be a string').email().required('email is required'),
    username: Yup.string('username must be a string').required('username is required'),
    password: Yup.string().required('password is required'),
    is_staff: Yup.boolean('is staff must be a checked'),
  });
  const defaultValues = {
    firstname: '',
    lastname: '',
    email: '',
    username: '',
    password: '',
    is_staff: false,
  };

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/admins/`,
        {
          first_name: data.firstname,
          last_name: data.lastname,
          username: data.username,
          email: data.email,
          password: data.password,
          is_staff: true,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAlert('Admin is created successfully!');
    } catch (err) {
      const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
      setErr(detail);
    }
  };

  return (
    <Page title="Create a new word">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Create a admin
          </Typography>
        </Stack>
        <HeaderBreadcrumbs
          heading={'Create a admin'}
          links={[
            { name: 'Dashboard', href: `/dashboard/app` },
            {
              name: 'admins',
              href: `/dashboard/admins`,
            },
            { name: 'Create admin' },
          ]}
        />
        {/* Backdrop */}
        <Backdrop tepa={400} chap={20} />
        <Backdrop tepa={300} chap={920} />

        <Card
          sx={{ padding: '15px', marginTop: '25px' }}
          style={{
            backdropFilter: 'blur(23px) saturate(180%)',
            WebkitBackdropFilter: 'blur(16px) saturate(180%)',
            backgroundColor: 'rgba(17, 25, 40, 0.55)',
            border: '1px solid rgba(255, 255, 255, 0.125)',
            zIndex: 2,
          }}
        >
          {err && <Error err={err} setErr={setErr} />}
          {alert && <Success alert={alert} setAlert={setAlert} />}
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} mb={3} direction={{ xs: 'column', sm: 'row' }}>
              <RHFTextField name="firstname" label={'Firstname'} />
              <RHFTextField name="lastname" label={'Lastname'} />
            </Stack>
            <Stack spacing={3} mb={3} direction={{ xs: 'column', sm: 'row' }}>
              <RHFTextField name="email" label={'Email'} />
              <RHFTextField name="username" label={'Username'} />
            </Stack>
            <Stack spacing={3} mb={3} direction={{ xs: 'column', sm: 'row' }}>
              <RHFTextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <RHFCheckbox name="is_staff" label="is staff" />
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Create admin
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}

import { useState } from 'react';

const useLocalStorage = (name, initial) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initial;
    }
    try {
      const item = window.localStorage.getItem(name);
      return item ? JSON.parse(item) : initial;
    } catch (error) {
      console.log(error);
      return initial;
    }
  });

  const setValue = (value, remember = false) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined' && remember === true) {
        window.localStorage.setItem(name, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
};

export default useLocalStorage;

import axios from 'axios';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from './authContext';

const NewWordContext = createContext();

NewWordProvider.propTypes = {
  children: PropTypes.any,
};

export function NewWordProvider({ children }) {
  const { token } = useAuthContext();
  const [newWords, setNewWords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);

  async function getNewWords(token) {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}words/admin/new-words/`, {
        headers: {
          Authorization: token,
        },
      });
      setNewWords(res.data);
    } catch (err) {
      const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
      setErr(detail);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getNewWords(token);
  }, [token]);

  const value = useMemo(
    () => ({
      newWords,
      setNewWords,
      loading,
      err,
    }),
    [newWords, setNewWords, loading, err]
  );
  return <NewWordContext.Provider value={value}>{children}</NewWordContext.Provider>;
}

export const useNewWordsContext = () => {
  const context = useContext(NewWordContext);
  if (context === 'undefined') {
    throw new Error('You have to use useUserContext inside UserProvider');
  }
  return context;
};

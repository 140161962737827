import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
// @mui
import { Box, Card, Container, Typography, Stack, Divider } from '@mui/material';
// components
import HeaderBreadcrumbs from '../../Breadcrumbs';
import { Error } from '../../Alert';
import Backdrop from '../../ui/backdrop';
import { useAuthContext } from '../../../contexts';
import { fDate } from '../../../utils/formatTime';

// sections

// ----------------------------------------------------------------------

export default function ContactsViewSection() {
  const { id } = useParams();
  const { token } = useAuthContext();

  const [contact, setContact] = useState([]);
  const [err, setErr] = useState(null);

  useEffect(() => {
    getContact(id);
  }, [id]);

  useEffect(() => {
    if (contact.unread === true) {
      (async () => {
        try {
          await axios.put(
            `${process.env.REACT_APP_BASE_URL}contact/admin/${id}/`,
            {
              ...contact,
              unread: false,
            },
            {
              headers: {
                Authorization: token,
              },
            }
          );
        } catch (err) {
          const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
          setErr(detail);
        }
      })();
    }
  }, [id, token, contact]);

  const getContact = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}contact/admin/${id}/`, {
        headers: {
          Authorization: token,
        },
      });
      setContact(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container>
        <Backdrop tepa={400} chap={20} />
        <Backdrop tepa={300} chap={920} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Feedback view</Typography>
        </Stack>
        <HeaderBreadcrumbs
          heading={'Create a dictionary'}
          links={[
            { name: 'Dashboard', href: `/dashboard/app` },
            {
              name: 'contacts',
              href: `/dashboard/contacts/list`,
            },
            { name: 'View contact' },
          ]}
        />
        <Card
          sx={{ padding: '15px', marginTop: '25px' }}
          style={{
            backdropFilter: 'blur(23px) saturate(180%)',
            WebkitBackdropFilter: 'blur(16px) saturate(180%)',
            backgroundColor: 'rgba(17, 25, 40, 0.55)',
            border: '1px solid rgba(255, 255, 255, 0.125)',
            zIndex: 2,
          }}
        >
          {err && <Error err={err} setErr={setErr} />}
          <Box sx={{ p: { xs: 3, md: 5 } }}>
            <Typography variant="subtitle1">Ismi: {contact.name}</Typography>
            <Typography variant="subtitle1">Email: {contact.email}</Typography>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
              Sana: {!contact.created_at ? 'nomalum' : fDate(contact.created_at)}
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Description
            </Typography>
            <Divider />
            <Typography sx={{ mt: 3 }}>{contact.description}</Typography>
          </Box>
        </Card>
      </Container>
    </>
  );
}

import PropTypes from 'prop-types';
import isString from 'lodash/isString';

// @mui
import { List, Stack, Button, IconButton, ListItemText, ListItem } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';

import Iconify from '../Iconify';
import FileICon from '../../assets/file.svg';

// ----------------------------------------------------------------------

const getFileData = (file) => {
  if (typeof file === 'string') {
    return {
      key: file,
    };
  }
  return {
    key: file.name,
    name: file.name,
    size: file.size,
    preview: file.preview,
  };
};

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
};

export default function MultiFilePreview({ files, onRemove, onRemoveAll }) {
  const hasFile = files.length > 0;

  return (
    <>
      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        {files.map((file) => {
          const { key, name, size } = getFileData(file);

          return (
            <ListItem
              key={key}
              component="div"
              sx={{
                my: 1,
                px: 2,
                py: 0.75,
                borderRadius: 0.75,
                border: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            >
              <img
                src={FileICon}
                sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }}
                alt="file"
                style={{ marginRight: 15 }}
              />
              <ListItemText
                primary={isString(file) ? file : name}
                secondary={isString(file) ? '' : fData(size || 0)}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'caption' }}
              />

              <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                <Iconify icon={'eva:close-fill'} />
              </IconButton>
            </ListItem>
          );
        })}
      </List>

      {hasFile && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            Remove all
          </Button>
          <Button size="small" variant="contained">
            Upload files
          </Button>
        </Stack>
      )}
    </>
  );
}

import axios from 'axios';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from './authContext';

const UserContext = createContext();

UserProvider.propTypes = {
  children: PropTypes.any,
};

export function UserProvider({ children }) {
  const { token } = useAuthContext();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);

  async function getUsers(token) {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}words/admin/word/`, {
        headers: {
          Authorization: token
        }
      });
      setUsers(res.data);
    } catch (err) {
      const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
      setErr(detail);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUsers(token);
  }, [token]);

  const value = useMemo(
    () => ({
      users,
      setUsers,
      loading,
      err,
    }),
    [users, setUsers, loading, err]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === 'undefined') {
    throw new Error('You have to use useUserContext inside UserProvider');
  }
  return context;
};

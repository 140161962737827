import { Alert, Box } from '@mui/material';
import PropTypes from 'prop-types';

Success.propTypes = {
  setAlert: PropTypes.any,
  alert: PropTypes.any,
};

function Success({ setAlert, alert }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '0',
        right: '0',
        maxWidth: '500px',
        width: '100%',
        padding: '20px',
        zIndex: 999,
      }}
    >
      <Alert
        variant="filled"
        severity="success"
        onClose={() => {
          setAlert(null);
        }}
      >
        {alert}
      </Alert>
    </Box>
  );
}

Error.propTypes = {
  setErr: PropTypes.any,
  err: PropTypes.any,
};

function Error({ setErr, err }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '0',
        right: '0',
        maxWidth: '500px',
        width: '100%',
        padding: '20px',
        zIndex: 999,
      }}
    >
      <Alert
        variant="filled"
        severity="error"
        onClose={() => {
          setErr(null);
        }}
      >
        {err}
      </Alert>
    </Box>
  );
}

export { Error, Success };

import './App.css';
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { AdminProvider, AuthProvider, DictionaryProvider, NewWordProvider, UserProvider } from './contexts';

// ----------------------------------------------------------------------

function MyApp() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <NewWordProvider>
          <DictionaryProvider>
            <AdminProvider>
              <MyApp />
            </AdminProvider>
          </DictionaryProvider>
        </NewWordProvider>
      </UserProvider>
    </AuthProvider>
  );
}

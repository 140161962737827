import axios from "axios";
import {useEffect, useState} from "react";
import { Container } from '@mui/material';
import Page from '../components/Page';
import LoadingStyle from '../components/LoadingStyle';
import ErrorStyle from '../components/ErrorStyle';
import {useAuthContext} from '../contexts';
import ContactsSection from "../components/pages-section/contacts/ContactsSection";

export default function Contacts() {
    const {token} = useAuthContext();

    const [contacts, setcontacts] = useState(null);
    const [err, setErr] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}contact/admin/`, {
                headers: {
                    Authorization: token
                }
            });
            setcontacts(res.data.results);
        } catch (err) {
            const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
            setErr(detail);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Page title="Contacts">
            <Container>
                {loading && <LoadingStyle />}
                {!loading && contacts && <ContactsSection contacts={contacts} setContacts={setcontacts} />}
                {!loading && !contacts && err && <ErrorStyle err={err} />}
            </Container>
        </Page>
    );
}

import * as Yup from 'yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { useAuthContext } from '../../../contexts';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { setToken, setUser } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);
  const [loginErr, setLoginErr] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoginErr(null);
    }, 3000);

    return () => clearInterval(interval);
  }, [loginErr]);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    username: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}auth/login/`, {
        username: data.username,
        password: data.password,
      });
      setLoginErr(null);
      setToken(res.data.token, watch('remember'));
      setUser(
        {
          username: data.username,
        },
        watch('remember')
      );
      navigate('/dashboard/app', { replace: true });
    } catch (err) {
      const detail = err.response.data?.detail ? err.response.data.detail : 'Something went wrong!';
      setLoginErr(detail);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="username" label="username" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>
      {loginErr && (
        <Typography variant="subtitle2" component="p" color={'#d62728'} marginBottom="15px" textAlign="center">
          {loginErr}
        </Typography>
      )}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}

// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: "So'zlar",
    path: '/dashboard/words',
    icon: getIcon('eva:book-fill'),
    children: [
      {
        title: "So'zlar ro'yhati",
        path: '/dashboard/words/list',
      },
      {
        title: "Yangi so'z yaratish",
        path: '/dashboard/words/create',
      },
    ],
  },
  {
    title: "Yangi so'zlar",
    path: '/dashboard/new-words',
    icon: getIcon('eva:clipboard-fill'),
    children: [
      {
        title: "Yangi so'zlar ro'yhati",
        path: '/dashboard/new-words/list',
      },
      {
        title: "Yangi so'zni tasdiqlash",
        path: '/dashboard/new-words/update',
      },
    ],
  },
  {
    title: "So'z yuklash",
    path: '/dashboard/dictionary-type',
    icon: getIcon('eva:folder-fill'),
    children: [
      {
        title: "So'z yuklash",
        path: '/dashboard/dictionary-upload/upload',
      },
    ],
  },
  {
    title: "Lug'at",
    path: '/dashboard/dictionary',
    icon: getIcon('eva:folder-fill'),
    children: [
      {
        title: "Lug'at ro'yhati",
        path: '/dashboard/dictionary/list',
      },
      {
        title: "Lug'at yaratish",
        path: '/dashboard/dictionary/create',
      },
      {
        title: "Lug'atni yangilash",
        path: '/dashboard/dictionary/update',
      },
    ],
  },
  {
    title: "Lug'at turi",
    path: '/dashboard/dictionary-type',
    icon: getIcon('eva:folder-fill'),
    children: [
      {
        title: "Lug'at turi ro'yhati",
        path: '/dashboard/dictionary-type/list',
      },
      {
        title: "Lug'at yaratish",
        path: '/dashboard/dictionary-type/create',
      },
    ],
  },
  {
    title: 'Adminlar',
    path: '/admins/dictionary',
    icon: getIcon('eva:person-fill'),
    children: [
      {
        title: "Adminlar ro'yhati",
        path: '/dashboard/admins/list',
      },
      {
        title: 'Admin yaratish',
        path: '/dashboard/admins/create',
      },
      {
        title: "Admini o'zgartirish",
        path: '/dashboard/admins/update',
      },
    ],
  },
];

export default navConfig;

import axios from 'axios';
import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';
import ErrorStyle from '../components/ErrorStyle';
import { useAuthContext } from '../contexts';
import AdminSection from '../components/pages-section/admins/AdminSection';

export default function Admins() {
  const { token } = useAuthContext();

  const [admin, setAdmin] = useState([]);
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}auth/admins/`, {
        headers: {
          Authorization: token,
        },
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        },
      });
      if (!totalRows) {
        setTotalRows(res.data.count);
      }
      if (admin) {
        res.data.results.forEach((el) => {
          const w = admin?.find((w) => w.id === el.id);
          if (!w) {
            setAdmin((prev) => [...prev, el]);
          }
        });
        return;
      }
      setAdmin(res.data.results);
    } catch (err) {
      const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
      setErr(detail);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  return (
    <Page title="Admins">
      <Container>
        <AdminSection
          admin={admin}
          setAdmin={setAdmin}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={totalRows}
          loading={loading}
        />
        {!loading && !admin && err && <ErrorStyle err={err} />}
      </Container>
    </Page>
  );
}

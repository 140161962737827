import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components

import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../sections/@dashboard/dictionary-type';
import { Error, Success } from '../../Alert';
import Backdrop from '../../ui/backdrop';
import LoadingStyle from '../../LoadingStyle';
import Breadcrumbs from '../../Breadcrumbs';

const TABLE_HEAD = [
  { id: 'id', label: 'id', alignRight: false },
  { id: 'title', label: 'title', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function applySortFilter(array, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (query) {
    return filter(array, (_user) => _user.title?.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

DictionaryType.propTypes = {
  dictionaryType: PropTypes.any,
  setDictionaryType: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  rowsPerPage: PropTypes.any,
  setRowsPerPage: PropTypes.any,
  loading: PropTypes.any,
  totalRows: PropTypes.any,
};

export default function DictionaryType({
  dictionaryType,
  setDictionaryType,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loading,
  totalRows,
}) {
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('title');

  const [filterName, setFilterName] = useState('');
  const [err, setErr] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(null);
      setErr(null);
    }, 3000);

    return () => clearInterval(interval);
  }, [err, alert]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dictionaryType.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, title) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilterName('');
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = useCallback((event) => {
    setFilterName(event.target.value);
  }, []);

  // async function searchUsers(token) {
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}dictionary/?search=${filterName.split(' ').join('+')}`,
  //       {
  //         headers: {
  //           Authorization: token,
  //         },
  //       }
  //     );
  //     setDictionaryType(res.data.results);
  //   } catch (err) {
  //     const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
  //     setErr(detail);
  //   }
  // }

  // useEffect(() => {
  //   searchUsers(token);
  // }, [token, filterName]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dictionaryType.length) : 0;

  const filteredDictionary = applySortFilter(dictionaryType, filterName);

  const isUserNotFound = filteredDictionary.length === 0;

  return (
    <>
      <Backdrop tepa={400} chap={20} />
      <Backdrop tepa={300} chap={920} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Dictionary Type</Typography>
      </Stack>
      <Breadcrumbs
        heading={'Create a dictionary'}
        links={[
          { name: 'Dashboard', href: `/dashboard/app` },
          {
            name: 'dictionary-type',
            href: `/dashboard/dictionary-type/list`,
          },
          { name: 'Dictionary type list' },
        ]}
      />
      <Card
        sx={{ padding: '15px', marginTop: '25px' }}
        style={{
          backdropFilter: 'blur(23px) saturate(180%)',
          WebkitBackdropFilter: 'blur(16px) saturate(180%)',
          backgroundColor: 'rgba(17, 25, 40, 0.55)',
          border: '1px solid rgba(255, 255, 255, 0.125)',
          zIndex: 2,
        }}
      >
        {err && <Error err={err} setErr={setErr} />}
        {alert && <Success alert={alert} setAlert={setAlert} />}
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            {loading && <LoadingStyle />}
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={dictionaryType.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />

              <TableBody>
                {filteredDictionary.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const isItemSelected = selected.indexOf(row.title) !== -1;

                  return (
                    <TableRow
                      hover
                      key={row.id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, row.title)} />
                      </TableCell>
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.title}</TableCell>

                      <TableCell align="right">
                        <UserMoreMenu
                          navigator={`/dashboard/dictionary-type/update/${row.id}`}
                          endpoint={`dictionary/dictionary-type/${row.id}/`}
                          id={row.id}
                          state={dictionaryType}
                          setState={setDictionaryType}
                          setAlert={setAlert}
                          setErr={setErr}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

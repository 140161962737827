import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import axios from 'axios';
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Alert,
  AlertTitle,
} from '@mui/material';
// components
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../sections/@dashboard/words';
import { Error } from '../../Alert';
import Label from '../../Label';
import Backdrop from '../../ui/backdrop';
import { useAuthContext } from '../../../contexts';
import LoadingStyle from '../../LoadingStyle';
import HeaderBreadcrumbs from '../../Breadcrumbs';

// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'id', alignRight: false },
  { id: 'title', label: 'title', alignRight: false },
  { id: 'checked', label: 'checked', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function applySortFilter(array, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (query) {
    return filter(array, (_user) => _user.title?.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

NewWordsSection.propTypes = {
  newWords: PropTypes.any,
  setNewWords: PropTypes.any,
  loading: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  rowsPerPage: PropTypes.any,
  setRowsPerPage: PropTypes.any,
  totalRows: PropTypes.any,
};

export default function NewWordsSection({
  newWords,
  setNewWords,
  loading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalRows,
}) {
  const { token } = useAuthContext();

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('title');

  const [filterName, setFilterName] = useState('');
  const [err, setErr] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(null);
      setErr(null);
    }, 3000);

    return () => clearInterval(interval);
  }, [err, alert]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = newWords.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilterName('');
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = useCallback((event) => {
    setFilterName(event.target.value);
  }, []);

  async function searchUsers(token) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}words/admin/new-words/?search=${filterName.split(' ').join('+')}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setNewWords(res.data.results);
    } catch (err) {
      const detail = err.response.data ? err.response.data.detail : 'Something went wrong';
      setErr(detail);
    }
  }

  useEffect(() => {
    searchUsers(token);
  }, [token, filterName]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newWords.length) : 0;

  const filteredUsers = applySortFilter(newWords, filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">new words list</Typography>
      </Stack>

      <Backdrop tepa={400} chap={20} />
      <Backdrop tepa={300} chap={920} />
      <HeaderBreadcrumbs
        heading={'Update a new word'}
        links={[
          { name: 'Dashboard', href: `/dashboard/app` },
          {
            name: 'words',
            href: `/dashboard/new-words`,
          },
          { name: 'update new word' },
        ]}
      />
      <Card
        sx={{ padding: '15px', marginTop: '25px' }}
        style={{
          backdropFilter: 'blur(23px) saturate(180%)',
          WebkitBackdropFilter: 'blur(16px) saturate(180%)',
          backgroundColor: 'rgba(17, 25, 40, 0.55)',
          border: '1px solid rgba(255, 255, 255, 0.125)',
          zIndex: 2,
        }}
      >
        {err && <Error err={err} setErr={setErr} />}
        {alert && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            New Word is deleted — <strong>check it out!</strong>
          </Alert>
        )}
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            {loading && <LoadingStyle />}
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={newWords.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const isItemSelected = selected.indexOf(row.title) !== -1;

                  return (
                    <TableRow
                      hover
                      key={row.id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, row.title)} />
                      </TableCell>
                      <TableCell align="left" sx={{ width: 100 }}>
                        <Label variant="ghost">{row.id}</Label>
                      </TableCell>
                      <TableCell align="left">{row.title}</TableCell>

                      <TableCell align="left">
                        <Label color={[null, false].includes(row.checked) ? 'error' : 'success'}>
                          {[null, false].includes(row.checked) ? 'false' : 'true'}
                        </Label>
                      </TableCell>

                      <TableCell align="right">
                        <UserMoreMenu
                          navigator={`/dashboard/new-words/update/${row.id}`}
                          endpoint={`words/admin/new-words/${row.id}/`}
                          id={row.id}
                          state={newWords}
                          setState={setNewWords}
                          setAlert={setAlert}
                          setErr={setErr}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} sx={{ py: 2 }} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

import {Box} from "@mui/material";

const Backdrop = ({tepa, chap}) => {
    return (
      <Box sx={{
          position: "absolute",
          top: tepa,
          right: chap,
          width: "356px",
          height: "356px",
          background: "#2A3E84",
          filter: "blur(175px)",
          zIndex: 1
      }} />
    );
}

export default Backdrop;
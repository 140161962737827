import { useLocation } from 'react-router-dom';
// @mui
import { Container, Stack, Typography, Card } from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useAuthContext } from '../../../contexts/authContext';
// routes
// components
import Page from '../../Page';
import HeaderBreadcrumbs from '../../Breadcrumbs';

import { FormProvider, RHFTextField } from '../../hook-form';
import Backdrop from '../../ui/backdrop';

export default function CraeteDictionaryType() {
  const { token } = useAuthContext();
  const location = useLocation();
  console.log(location);

  const Schema = Yup.object().shape({
    title: Yup.string('category must be a string').required('category is required'),
  });

  const defaultValues = {
    title: '',
  };

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}dictionary/dictionary-type/`,
        {
          title: data.title,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page title="Create Dictionary type">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Yangi Lug'at turini yaratish
          </Typography>
        </Stack>
        <HeaderBreadcrumbs
          heading={'Create dictionary type'}
          links={[
            { name: 'Dashboard', href: `/dashboard/app` },
            {
              name: 'dictionary type',
              href: `/dashboard/dictionary-type`,
            },
            { name: 'Create Dictionary type' },
          ]}
        />
        <Backdrop tepa={300} chap={20} />
        <Backdrop tepa={250} chap={990} />
        <Card
          sx={{ padding: '15px', marginTop: '25px' }}
          style={{
            backdropFilter: 'blur(23px) saturate(180%)',
            WebkitBackdropFilter: 'blur(16px) saturate(180%)',
            backgroundColor: 'rgba(17, 25, 40, 0.55)',
            border: '1px solid rgba(255, 255, 255, 0.125)',
            zIndex: 2,
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} mb={3}>
              <RHFTextField name="title" label="Lug'at turi..." />
            </Stack>
            <Stack>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Yaratish
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}
